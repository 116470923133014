<template>
  <!-- Error page-->
  <div class="misc-wrapper box">
    <div class="misc-inner p-2 p-sm-3">
      <div class="box">
        <h2 class="title-error">
          Ops, algo deu errado…
        </h2>
        <h2 class="mb-1 text-color">
          Página não encontrada
        </h2>
        <a
          :href="redirect"
          class="a-gohome"
        > Voltar para página inicial </a>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
export default {
    data() {
        return {
            redirect: null,
        };
    },

    created() {
        const token = sessionStorage.getItem('token');
        if (token === null) { this.redirect = '/login'; } else { this.redirect = '/'; }
    },

};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-misc.scss';
</style>
